import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

let role = 2

const getRoute = () => {
  if (localStorage.getItem('user'))
    role = JSON.parse(localStorage.getItem('user')).role
  if (role == 1)
    return [
      {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/dashboard',
        children: [
          {
            path: '/dashboard',
            name: 'Dashboard',
            component: () => import('@/views/Dashboard.vue'),
          },
          {
            path: '/summary',
            name: 'Summary',
            component: () => import('@/views/Summary.vue'),
          },
          {
            path: '/user',
            name: 'User',
            component: () => import('@/views/User.vue'),
          },
          {
            path: '/driverId',
            name: 'DriverID',
            component: () => import('@/views/DriverId.vue'),
          },
          {
            path: '/scenario',
            name: 'Scenario',
            component: () => import('@/views/Scenario.vue'),
          },
          {
            path: '/license',
            name: 'License Plate',
            component: () => import('@/views/License.vue'),
          },
          {
            path: '/fleet-manager',
            name: 'Manage Fleet',
            component: () => import('@/views/FleetManager.vue'),
          },
          {
            path: '/sitedetail',
            name: 'Site Details',
            component: () => import('@/views/SiteDetail.vue'),
          },
          {
            path: '/safety-training',
            name: 'Safety Training',
            component: () => import('@/views/SafetyTraining.vue'),
          },
          {
            path: '/admin',
            name: 'Manage Admin',
            component: () => import('@/views/Admin.vue'),
          },
          {
            path: '/useCase',
            name: 'Use Case',
            component: () => import('@/views/UseCase.vue'),
          },
          {
            path: '/notification',
            name: 'Notification',
            component: () => import('@/views/Notification.vue'),
          },
        ],
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/Login'),
      },
    ]
  return [
    {
      path: '/',
      name: 'Home',
      component: DefaultLayout,
      redirect: '/dashboard',
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('@/views/Dashboard.vue'),
        },
        {
          path: '/license',
          name: 'License Plate',
          component: () => import('@/views/License.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login'),
    },
  ]
}

const routes = getRoute()

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
