import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { LoadingPlugin } from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCPYWTtrLeI7LTWAAkAPKuPj8AgbsxDzbs',
  },
})
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.use(LoadingPlugin)
app.mount('#app')
