export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Summary',
    to: '/summary',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'User',
    to: '/user',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Scenario',
    to: '/scenario',
    icon: 'cil-pencil',
  },
  /*{
    component: 'CNavItem',
    name: 'Driver ID',
    to: '/driverId',
    icon: 'cil-notes',
  },*/
  {
    component: 'CNavItem',
    name: 'License Plate',
    to: '/license',
    icon: 'cil-cursor',
  },
  {
    component: 'CNavItem',
    name: 'Fleet Manager',
    to: '/fleet-manager',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Site Details',
    to: '/sitedetail',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Use Case',
    to: '/usecase',
    icon: 'cil-basket',
  },
  {
    component: 'CNavItem',
    name: 'Notification',
    to: '/notification',
    icon: 'cil-bell',
  },
  {
    component: 'CNavItem',
    name: 'Safety Training',
    to: '/safety-training',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Manage Admin',
    to: '/admin',
    icon: 'cil-user',
  },
]
